import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlain from './SelectSingleFilterPlain';
import SelectSingleFilterPopup from './SelectSingleFilterPopup';
import FilterPlain from '../FilterPlain/FilterPlain';
import css from './SelectSingleFilter.module.css';
import { pickInitialValuesForFieldSelectTree } from '../../../util/search';
import { useConfiguration } from '../../../context/configurationContext';
import classNames from 'classnames';
import FieldSelectTree from '../FieldSelectTree/FieldSelectTree';
const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};
const SelectSingleFilter = props => {
  const { rootClassName,
    className,
    showAsPopup,
    options,
    isNestedEnum,
    id,
    name,
    label,
    queryParamNames,
    initialValues,
    contentPlacementOffset,
    onSubmit, ...rest } = props;
    const queryParamName = getQueryParamName(queryParamNames);

    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];

  const classes = classNames(rootClassName || css.root, className);
  const labelClass = hasInitialValues ? css.labelPlainSelected : css.labelPlain;
  const labelForPlain = <span className={labelClass}>{label}</span>;

  const pickedInitialValues = { [name]: pickInitialValuesForFieldSelectTree(name, initialValues) };

  const handleSubmit = queryParamNames => values => {
    const isArray = Array.isArray(queryParamNames);
    const hasMultipleQueryParams = isArray && queryParamNames.length > 1;
    const hasSingleQueryParam = isArray && queryParamNames.length === 1;
    const firstQueryParamName = isArray ? queryParamNames[0] : queryParamNames;

    // Nested options create multiple query params
    //   - E.g. categoryLevel1: 'v1', categoryLevel2: 'v2', categoryLevel3: 'v3'
    // If there's only a single query param in use, we flatten the value
    //   - E.g. categoryLevel: 'value'
    const usedValue = hasMultipleQueryParams
      ? queryParamNames.reduce((acc, p, i) => {
          const k = `${name}${i + 1}`;
          const v = values?.[name]?.[k] || null;
          return { ...acc, [p]: v };
        }, {})
      : hasSingleQueryParam
      ? { [firstQueryParamName]: values?.[name]?.[`${name}1`] || null }
      : values;
    onSubmit(usedValue);
  };
  
  return showAsPopup ? (
    // <SelectSingleFilterPopup {...rest} />
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={labelForPlain}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleSubmit(queryParamNames)}
      initialValues={pickedInitialValues}
      {...rest}
    >
      <FieldSelectTree name={name} options={options} />
    </FilterPlain>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={labelForPlain}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      onSubmit={handleSubmit(queryParamNames)}
      initialValues={pickedInitialValues}
      {...rest}
    >
      <FieldSelectTree name={name} options={options} />
    </FilterPlain>
    // <SelectSingleFilterPlain {...rest} />
  );
};

SelectSingleFilter.defaultProps = {
  showAsPopup: false,
  isNestedEnum: false,

};

SelectSingleFilter.propTypes = {
  showAsPopup: bool,
  isNestedEnum: bool,

};

export default SelectSingleFilter;
